<template>
  <div :class="$style.buttons">
    <slot name="content">
      <lv-heading
        v-if="content.title && showTitle"
        tag="h2"
        level="3"
        :style="{color: standardComponentTextColor}"
        :class="$style.title"
        align="center"
      >
        {{ content.title }}
      </lv-heading>
      <div
        v-if="content.button"
        :class="$style.container"
        :style="{
          'justify-content': content.button.alignment,
          '--awButtonsColCount': content.button.items?.length > 2 ? 3 : content.button.items?.length,
        }"
        v-bind="containerAttrs"
      >
        <lv-button
          v-for="(button, index) in (content.button?.items || []).slice(0, limit)"
          :key="`button-${index}`"
          :styling="getStyling(button)"
          :loading="isLoadingCmsModalContent"
          v-bind="(buttonsAttrsComp[index] || {}).attributes"
          :class="$style.button"
          v-on="(buttonsAttrsComp[index] || {}).listeners"
        >
          <slot :name="`buttonText_${index}`">
            {{ button.text }}
          </slot>
        </lv-button>
      </div>
      <aw-modal
        v-if="modalType === modalId"
        size="lg"
        :modal-title="modalContent?.title"
        :opened="modalType === modalId"
        @close-modal="closeModal"
      >
        <template #content>
          <aw-cms-page :content-data="modalContent" force-full-width />
        </template>
      </aw-modal>
    </slot>
  </div>
</template>

<script>
  import { mapState } from 'pinia';
  import { defineAsyncComponent } from 'vue';
  import { useId } from 'nuxt/app';
  import standardComponentsMixin from '~~/common/mixins/standardComponentsMixin';
  import analyticsBannerInteractionMixin from '~~/common/mixins/analyticsBannerInteractionMixin.js';
  import { useUserInterfaceStore } from '~~/common/stores/userInterface';
  import { useContentsStore } from '~~/common/stores/contents.js';
  import { useUrlResourceStore } from '~~/common/stores/urlResource.js';
  import { useModalStore } from '~~/common/stores/modal.js';
  import { M_BUTTONS_CMS_CONTENT } from '~~/common/config/Modal.js';
  import { LvButton, LvHeading } from '~~/common/components/loginet-vue-shop/index.mjs';
  import AwModal from '~~/common/components/Common/AwModal.vue';

  const MODAL_BUTTON_LINK_PREFIX = 'modal:/';

  export default {
    name: 'AwButtons',
    components: {
      LvButton,
      LvHeading,
      AwModal,
      AwCmsPage: defineAsyncComponent(() => import('~~/common/components/Page/Content/AwCmsPage.vue')),
    },
    mixins: [
      standardComponentsMixin,
      analyticsBannerInteractionMixin,
    ],
    props: {
      content: {
        type: Object,
        required: true,
      },
      containerAttrs: {
        type: Object,
        default: () => ({}),
      },
      buttonAttrs: {
        type: Object,
        default: () => ({}),
      },
      showTitle: {
        type: Boolean,
        default: true,
      },
      limit: {
        type: Number,
        default: Infinity,
      },
      generateBtnLink: {
        type: Function,
        default: (button) => {
          return button?.link || '';
        },
      },
      isBtnInteractive: {
        type: Function,
        default: null,
      },
    },
    data () {
      return {
        isLoadingCmsModalContent: false,
        modalId: M_BUTTONS_CMS_CONTENT + useId(),
        MODAL_BUTTON_LINK_PREFIX,
      };
    },
    computed: {
      buttonsAttrsComp () {
        return this.content.button.items.map((button) => {
          const isInteractive = this.isBtnInteractive?.(button) ?? !this.content.link;
          const generatedLink = this.generateBtnLink(button);
          const isModalButton = generatedLink?.startsWith(MODAL_BUTTON_LINK_PREFIX);
          const listeners = (isInteractive
            ? this.genBannerInteractionClickEventHandlers({
              title: this.content.campaignName,
            })
            : {}
          );
          if (isModalButton) {
            const interactionClickHandler = listeners?.click ?? function () {};
            listeners.click = () => {
              interactionClickHandler();
              this.openCmsModalContentIfAvailable(generatedLink);
            };
          }

          return {
            attributes: {
              ...(isInteractive
                ? {
                  ...(isModalButton
                    ? {
                      to: null,
                    }
                    : this.$hyperlinkComputeBindFromUrl(generatedLink)
                  ),
                }
                : { tag: 'div' }
              ),
              ...this.buttonAttrs,
            },
            listeners,
          };
        });
      },
      ...mapState(useUserInterfaceStore, {
        screenRange: state => state.mediaQueries,
      }),
      ...mapState(useContentsStore, {
        modalContent: state => state.modalContent,
      }),
      ...mapState(useModalStore, {
        modalType: state => state.activeGenericModal?.type,
      }),
    },
    methods: {
      getStyling (button) {
        const replaceLinkPrimaryType = (styling) => {
          return styling === 'link-primary' ? 'link-primary-version2' : styling;
        };
        const desktopStyling = replaceLinkPrimaryType(button.type || 'primary');
        const mobileStyling = replaceLinkPrimaryType(button?.mobileType);

        return this.screenRange['tablet-min'] ? desktopStyling : mobileStyling || desktopStyling;
      },
      async openCmsModalContentIfAvailable (generatedLink) {
        if (!generatedLink?.startsWith(MODAL_BUTTON_LINK_PREFIX) || this.isLoadingCmsModalContent) {
          return;
        }
        const contentLink = generatedLink.replace(MODAL_BUTTON_LINK_PREFIX, '');

        this.isLoadingCmsModalContent = true;
        try {
          const contentsStore = useContentsStore();
          const urlResourceStore = useUrlResourceStore();
          urlResourceStore.setModalData(null);
          await urlResourceStore.fetchModalUrlResourceByUrl(contentLink);
          const modalData = urlResourceStore.modalData;
          if (!modalData?.resourceId || modalData?.resourceType !== 'cms_content') {
            this.isLoadingCmsModalContent = false;
            return;
          }
          await contentsStore.fetchModalContent({ pageId: modalData.resourceId, awCmsContentHref: window.encodeURI(generatedLink) });
          useModalStore().showCmsContentModalModal({ modalType: this.modalId });
        } catch (err) {
          this.$logger.error(err);
        } finally {
          this.isLoadingCmsModalContent = false;
        }
      },
      closeModal () {
        useModalStore().hideCmsContentModal({ modalType: this.modalId });
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.buttons {
  display: flex;
  flex-direction: column;
}

.container {
  display: grid;
  align-items: center;
  flex-grow: 1;
  flex-wrap: wrap;
  gap: 16px;
  grid-template-columns: repeat(1, auto);

  @include sm(min) {
    grid-template-columns: repeat(var(--awButtonsColCount), auto);
  }

  .button {
    text-align: center;
  }
}

.title {
  margin: 24px 0;
}
</style>
